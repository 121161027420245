<template>
  <el-aside :class="{open: !isCollapse}">
    <el-menu class="el-menu-vertical-demo" :collapse="isCollapse">
      <el-menu-item index="0" @click="toggleMenu" class="expand-menu hidden-sm-and-down">
        <i class="el-icon-menu"></i>
      </el-menu-item>

      <template v-for="(item, index) in menuItems">
        <template v-if="menuAllow(item)">
          <el-menu-item :index="`${index + 1}`" v-if="!item.meta.hasOwnProperty('children') && !item.meta.notMenu" :key="item.meta.title">
            <router-link :to="item" class="el-link el-link--default is-underline">
              <i :class="item.meta.icon" v-if="item.meta.icon"></i>
              <span>{{ $t(item.meta.title) }}</span>
            </router-link>
          </el-menu-item>

          <el-submenu :index="`${index + 1}`" :key="item.meta.title" v-else-if="!item.meta.notMenu">
            <template slot="title">
              <i :class="item.meta.icon" v-if="item.meta.icon"></i>
              <span slot="title">{{ $t(item.meta.title) }}</span>
            </template>

            <template v-for="(subitem, subindex) in item.meta.children">
              <el-menu-item :index="`${index}-${subindex}`" :key="subitem.meta.title" v-if="!subitem.meta.notMenu">
                <router-link :to="subitem" class="el-link el-link--default is-underline">
                  {{ $t(subitem.meta.title) }}
                </router-link>
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
      </template>
    </el-menu>
  </el-aside>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NavigationMenu',
  data () {
    return {
      isCollapse: false
    }
  },
  computed: {
    ...mapGetters([
      'menuItems'
    ])
  },
  methods: {
    menuAllow (item) {
      const profile = this.$auth.getProfile()
      return (
        Object.prototype.hasOwnProperty.call(item.meta, 'profile') &&
        item.meta.profile.includes(profile.type)
      )
    },
    toggleMenu () {
      this.$set(this, 'isCollapse', !this.isCollapse)
      localStorage.setItem('toggleMenu', this.isCollapse)
    },
    settingMenu () {
      // Collapse menu on mobile
      if (document.body.offsetWidth <= 800) {
        this.$set(this, 'isCollapse', true)
      } else {
        const toggleMenu = localStorage.getItem('toggleMenu')
        this.$set(this, 'isCollapse', (toggleMenu) ? JSON.parse(toggleMenu) : false)
      }
    }
  },
  mounted () {
    this.settingMenu()
  }
}
</script>
<style lang="sass">
.el-aside
  overflow: hidden !important
  width: 64px !important

  &.open
    width: 200px !important

  .expand-menu
    background-color: #409EFF !important

    i
      color: white !important
</style>
