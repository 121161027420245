export default {
  path: '/uvlatam',
  name: 'UVLatam',
  component: () => import('@/views/admin/uvlatam'),
  meta: {
    icon: 'el-icon-document',
    title: 'menu.admin.uvlatam',
    profile: [
      'admin'
    ]
  }
}
