export default function (Vue) {
  Vue.auth = {
    // FIXME: Reparar a ver si se expira o no el token
    // always send expiration as a valid Date object
    setToken (token, expiration) {
      sessionStorage.setItem('token', token)
      Vue.auth.updateHeaders()
    },
    getToken () {
      const match = sessionStorage.getItem('token')
      if (match) {
        return match
      }
      return null
    },
    destroyToken () {
      sessionStorage.removeItem('token')
      Vue.auth.removeProfile()
    },
    updateHeaders (newToken) {
      if (Vue.http) {
        const token = newToken || Vue.auth.getToken()
        if (!token) {
          delete Vue.http.headers.common.Authorization
          return null
        } else {
          if (newToken) {
            Vue.auth.setToken(newToken, null)
          }
        }
        Vue.http.headers.common.Authorization = `Basic ${token}`
      } else {
        return console.warn('Vue Resource not be installed!!!')
      }
    },
    isAuth () {
      return !!Vue.auth.getToken()
    },
    checkPermission (to, from, next) {
      // Chequear la existencia de zona horaria
      const profile = Vue.auth.getProfile()
      if (profile && !Object.prototype.hasOwnProperty.call(profile, 'timezone')) {
        profile.timezone = 'America/Bogota'
        Vue.auth.addProfile(profile)
      } else if (profile && profile.timezone.length < 8) {
        profile.timezone = 'America/Bogota'
        Vue.auth.addProfile(profile)
      }

      if (to.name !== 'Login' && !Vue.auth.isAuth()) return next({ name: 'Login' })
      else if (to.name === 'Login' && Vue.auth.isAuth()) return next((profile.isModAdmin === 'admin') ? { name: 'AdminHome' } : { name: 'Home' })
      else return next()
    },
    // TODO: Hacer un plugins a parte
    getProfile () {
      let profile
      const sessionData = sessionStorage.getItem('user')

      if (sessionData) {
        profile = JSON.parse(sessionData)
        profile.type = 'user'

        if (profile.isModAdmin) {
          profile.type = 'client'
        }

        if (profile.isStaff) {
          profile.type = 'admin'
        }
      }

      return profile
    },
    addProfile (profile) {
      this.user = profile
      sessionStorage.setItem('user', JSON.stringify(profile))
      sessionStorage.setItem('perPage', JSON.stringify(profile.perPage))
    },
    removeProfile () {
      sessionStorage.removeItem('user')
    }
  }

  Object.defineProperties(Vue.prototype, {
    $auth: {
      get: () => Vue.auth
    }
  })

  Vue.auth.updateHeaders()
}
