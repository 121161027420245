export default {
  name: 'Fanpage',
  meta: {
    icon: 'el-icon-document',
    title: 'menu.admin.fanpages',
    profile: [
      'admin'
    ],
    children: [
      {
        path: '/fanpage',
        name: 'FanpageList',
        component: () => import('@/views/admin/fanpage/index'),
        meta: {
          title: 'commons.index'
        }
      },
      {
        path: '/fanpage/new',
        name: 'FanpageNew',
        component: () => import('@/views/admin/fanpage/new'),
        meta: {
          title: 'commons.new'
        }
      },
      {
        path: '/fanpage/edit/:idFanpage',
        name: 'FanpageEdit',
        component: () => import('@/views/admin/fanpage/new'),
        meta: {
          notMenu: true
        }
      }
    ]
  }
}
