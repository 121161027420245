export default {
  name: 'Billing',
  path: '/billing',
  component: () => import('@/views/admin/clients/billing'),
  meta: {
    icon: 'el-icon-money',
    title: 'menu.admin.billing',
    profile: [
      'admin'
    ]
  }
}
