import Vue from 'vue'
import VueI18N from 'vue-i18n'
import es from './es.json'

Vue.use(VueI18N)

const i18n = new VueI18N({
  locale: 'es',
  fallbackLocale: 'es',
  messages: {
    es: es
  }
})

export default i18n
