import Vue from 'vue'
import VueRouter from 'vue-router'

import menuModule from '@/store/menu/'

Vue.use(VueRouter)

const generateMenuFromVuex = (menu = [], routes = []) => {
  for (let index = 0; index < menu.length; index++) {
    const element = menu[index]
    if (element.path) {
      routes.push(element)
    }
    if (element.meta.children) {
      generateMenuFromVuex(element.meta.children, routes)
    }
  }
  return routes
}

const routes = [
  ...generateMenuFromVuex(menuModule.state.items),
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/commons/404')
  },
  {
    path: '*',
    redirect: { name: '404' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
