export default {
  path: '/admin',
  name: 'AdminHome',
  component: () => import('@/views/admin/home'),
  meta: {
    icon: 'el-icon-s-home',
    title: 'menu.commons.home',
    profile: [
      'admin'
    ]
  }
}
