<template>
  <layout v-if="isAuth" />
  <auth-layout v-else />
</template>
<script>
import AuthLayout from '@/views/layouts/auth'
import Layout from '@/views/layouts/default'

export default {
  data () {
    return {
      isAuth: false
    }
  },
  watch: {
    $route () {
      this.checkLogin()
    }
  },
  methods: {
    checkLogin () {
      this.$set(this, 'isAuth', this.$auth.isAuth())
    }
  },
  mounted () {
    this.checkLogin()
  },
  components: {
    Layout,
    AuthLayout
  }
}
</script>
<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap')

*
  font-family: 'Noto Sans', sans-serif

body
  background-color: #EEE
  margin: 0

a
  text-decoration: none

.el-button+.popconfirm--wrap
  margin-left: 10px

.text-center
  text-align: center
</style>
