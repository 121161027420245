import Login from './login'

import AdminHome from './admin/home'
import Fanpage from './admin/fanpage'
import Billing from './admin/billing'
import UVLatam from './admin/uvlatam'

const state = {
  items: [
    Login,

    // Admin
    AdminHome,
    Fanpage,
    Billing,
    UVLatam
  ]
}

export default {
  state
}
