<template>
  <el-container>
    <el-header>
      <main-header />
    </el-header>
    <el-container>
      <navigation-menu />

      <el-main>
        <h3>{{ $t($route.meta.title) }}</h3>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import MainHeader from './partials/header'
import NavigationMenu from './partials/menu'

export default {
  name: 'DefaultLayout',
  components: {
    MainHeader,
    NavigationMenu
  }
}
</script>
<style lang="sass">
.el-header
  background-color: #232933

  .header__logo
    align-items: center
    display: flex
    justify-content: center

    img
      width: 80%

</style>
