<template>
  <el-row type="flex" justify="space-between">
    <el-col :xl="2" :lg="3" :md="4" :sm="8" class="header__logo hidden-sm-and-down">
      <router-link :to="{ name: 'Home' }">
        <img src="../../../assets/logo.svg" />
      </router-link>
    </el-col>
    <el-col :xs="4" class="header__logo hidden-md-and-up">
      <router-link :to="{ name: 'Home' }">
        <img src="../../../assets/isologo.svg" />
      </router-link>
    </el-col>
    <el-col :xl="2" :lg="3" :md="3" :sm="6" :xs="12">
      <el-menu mode="horizontal" background-color="#232933" color="#909399" text-color="#909399">
        <el-submenu index="1">
          <template slot="title">
            <span slot="title">{{ profile }}</span>
            <i class="el-icon-user-solid"></i>
          </template>
          <el-menu-item index="1-1">
            Link
          </el-menu-item>
          <el-menu-item index="1-2" @click="logout">
            {{ $t('menu.commons.signout') }}
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: 'MainHeader',
  data () {
    return {
      profile: null
    }
  },
  methods: {
    logout () {
      this.$auth.destroyToken()
      this.$router.push({ name: 'login' })
    }
  },
  mounted () {
    const profile = this.$auth.getProfile()
    if (profile) {
      this.$set(this, 'profile', profile.firstName)
    }
  }
}
</script>
