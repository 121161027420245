import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Auth from './plugins/auth'
import VueResource from 'vue-resource'
import { API_URL } from './config/'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import lang from 'element-ui/lib/locale/lang/es'
import locale from 'element-ui/lib/locale'

import i18n from '@/i18n'

import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'

Vue.use(Element)
Vue.use(VueResource)
Vue.use(Auth)

Vue.use(HighchartsVue)
Vue.use(Highcharts)

locale.use(lang)

Vue.http.options.root = API_URL

Vue.config.productionTip = false

let loading
router.beforeEach((_, __, next) => {
  loading = Element.Loading.service({
    lock: true
  })
  next()
})

router.beforeEach(Vue.auth.checkPermission)

router.afterEach(() => {
  loading.close()
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
